<template>
  <transition>
    <footer
      v-if="acf_options"
      id="site-footer"
      class="site-footer">
      <div class="gutter-wrapper">
        <div class="footer-container">
          <div class="footer-head">
            <div class="col w-full lg:w-5/12">
              <router-link
                :to="'/'"
                class="footer-logo"
                title="Home">
                <img
                  v-if="acf_options.site_logo_footer"
                  class="h-auto w-20 lg:w-auto max-w-44 mb-12 lg:mb-0"
                  :src="acf_options.site_logo_footer.url"
                  :alt="acf_options.site_logo_footer.alt" />
              </router-link>
            </div>
            <div class="col w-full lg:w-7/12 flex">
              <div class="col w-9/12 lg:w-6/12">
                <div
                  class="contact-info"
                  v-html="acf_options.footer_contact_info"></div>
                <div class="social-media">
                  <div class="menus-container">
                    <div class="footer-col-1">
                      <FooterMenu name="footer-menu-column-1" />
                    </div>
                    <div class="footer-col-2">
                      <FooterMenu name="footer-menu-column-2" />
                    </div>
                  </div>
                  <div class="social-container flex gap-4">
                    <a
                      v-if="acf_options.linkedin"
                      :href="acf_options.linkedin"
                      target="_blank">
                      <svg
                        width="39"
                        height="38"
                        viewBox="0 0 39 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <mask
                          id="mask0_2019_2330"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="39"
                          height="38">
                          <rect
                            x="0.19043"
                            width="38"
                            height="38"
                            fill="inherit" />
                        </mask>
                        <g mask="url(#mask0_2019_2330)"></g>
                        <g clip-path="url(#clip0_2019_2330)">
                          <path
                            d="M38.1904 2.80778V35.1922C38.1904 36.7333 36.9449 38 35.3827 38H2.99821C1.4571 38 0.19043 36.7544 0.19043 35.1922V2.80778C0.19043 1.24556 1.43599 0 2.99821 0H35.3827C36.9238 0 38.1904 1.24556 38.1904 2.80778ZM11.506 14.2289H5.78487V32.3844H11.506V14.2289ZM11.9493 8.42333C11.9493 6.52333 10.4715 5.15111 8.65599 5.15111C6.84043 5.15111 5.36265 6.54444 5.36265 8.42333C5.36265 10.3022 6.84043 11.6956 8.65599 11.6956C10.4715 11.6956 11.9493 10.3022 11.9493 8.42333ZM32.5749 21.1533C32.5749 15.6011 29.1338 13.7222 25.946 13.7222C22.9904 13.7222 21.0693 15.6222 20.5204 16.7411H20.4571V14.2289H14.9682V32.3844H20.6893V22.5467C20.6893 19.9289 22.3571 18.6411 24.046 18.6411C25.6504 18.6411 26.896 19.5489 26.896 22.4622V32.3844H32.6171V21.1533H32.5749Z"
                            fill="#F4F3ED" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2019_2330">
                            <rect
                              width="38"
                              height="38"
                              fill="inherit"
                              transform="translate(0.19043)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>

                    <a
                      v-if="acf_options.x"
                      :href="acf_options.x"
                      target="_blank">
                      <svg
                        width="37"
                        height="38"
                        viewBox="0 0 37 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M18.3699 16.5404L11.0427 5.54004H7.13379L16.2136 19.1711L17.3543 20.8832L25.1267 32.5517H29.0357L19.5106 18.2524L18.3699 16.5404Z"
                          fill="#F4F3ED" />
                        <path
                          d="M33.5378 0H2.67165C1.19372 0 0 1.23286 0 2.74411V35.2559C0 36.7671 1.19372 38 2.67165 38H33.5188C34.9968 38 36.1905 36.7671 36.1905 35.2559V2.74411C36.2094 1.23286 35.0157 0 33.5378 0ZM23.9293 34.4704L16.064 22.458L6.2168 34.4704H3.6721L14.9347 20.732L3.67021 3.52957H12.2612L19.7096 14.9057L29.0339 3.52957H31.5786L20.8389 16.6297L32.5184 34.4684H23.9274L23.9293 34.4704Z"
                          fill="#F4F3ED" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col w-3/12 lg:w-6/12 fine-print">
                <p class="rights mb-7 fine-print">
                  © {{ currentYear }} Trace Neuroscience
                </p>
                <div class="flex flex-col">
                  <template
                    v-for="(link, indx) of acf_options.footer_links"
                    :key="`footer-link-${indx}`">
                    <a
                      :href="link.link.url"
                      :target="link.link.target"
                      class="mb-7 underline"
                      >{{ link.link.title }}</a
                    >
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </transition>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, computed, onBeforeMount, watch } from 'vue'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import useSiteLoading from '@/composables/useSiteLoading'

const { siteLoading } = useSiteLoading()

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const acf_options = ref(null)

const currentYear = computed(() => {
  return new Date().getFullYear()
})

watch(siteLoading, () => {
  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 500)
})

onBeforeMount(async () => {
  acf_options.value = await store.acfOptions()
})
</script>

<style lang="scss">
.site-footer {
  @apply w-full mx-auto z-1 bg-gradient-to-r from-[-100%] from-trace-purple-500 to-primary text-white py-16 lg:py-20 relative overflow-hidden;
}

.footer-head {
  @apply flex flex-col lg:flex-row lg:justify-between container;

  .footer-logo {
    @apply h-fit;
  }
}
</style>
